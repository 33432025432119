import { QueryClientProvider } from "@tanstack/react-query";
import { useReducer, useState } from "react";

import newDisbursementsReducer, {
  initialState,
  Steps,
} from "reducers/newDisbursementsReducer";
import BulkUpload from "./BulkUpload";
import ConfirmDisbursement from "./ConfirmDisbursement";
import DisbursementType from "./DisbursementType";
import ManualUpload from "./ManualUpload";
import ProcessingFile from "./ProcessingFile";
import { VerificationCode } from "./VerificationCode";

import { queryClient } from "config/reactQueryClient";
import { useMerchantInfo } from "repositories/merchant/useMerchantInfo";

export const CreateDisbursementRoot = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <CreateDisbursement />
    </QueryClientProvider>
  );
};

const CreateDisbursement = () => {
  const { data: merchantInfo, isLoading } = useMerchantInfo();

  const [state, dispatch] = useReducer(newDisbursementsReducer, initialState);
  const [currentStep, setCurrentStep] = useState<number>(1);

  const isEnabledBulkInternationalPayout =
    !isLoading &&
    merchantInfo?.currentMerchant?.isEnabledBulkInternationalPayout;

  return (
    <main className="create-link">
      {currentStep == Steps.DisbursementType && (
        <DisbursementType setCurrentStep={setCurrentStep} />
      )}
      {currentStep == Steps.BulkUpload && (
        <BulkUpload
          dispatch={dispatch}
          setCurrentStep={setCurrentStep}
          onPrevStep={() => setCurrentStep(Steps.DisbursementType)}
          isEnabledBulkInternationalPayout={isEnabledBulkInternationalPayout}
        />
      )}
      {currentStep == Steps.ManualUpload && (
        <ManualUpload
          state={state}
          dispatch={dispatch}
          setCurrentStep={setCurrentStep}
          onPrevStep={() => setCurrentStep(Steps.DisbursementType)}
        />
      )}
      {currentStep == Steps.ConfirmDisbursement && (
        <ConfirmDisbursement
          state={state}
          setCurrentStep={setCurrentStep}
          onPrevStep={() => setCurrentStep(Steps.ManualUpload)}
        />
      )}
      {currentStep == Steps.VerificationCode && (
        <VerificationCode state={state} setCurrentStep={setCurrentStep} />
      )}
      {currentStep == Steps.ProcessingFile && <ProcessingFile />}
    </main>
  );
};
