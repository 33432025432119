import type { WalletTransaction } from "features/Wallet/WalletV2/typeDefs";
import type { PaginatedRequest } from "repositories/_base/pagination.types";
import { Repository } from "repositories/_base/repository";
import type { ApiResponse } from "repositories/_base/repository.types";

import type { Card } from "types/Card";
import type { Wallet } from "types/Wallet";

class FinancesRepository extends Repository {
  constructor() {
    super({
      gateway: "/finances",
    });
  }

  public async getFinances() {
    return this._http
      .get<ApiResponse<{ wallets: Wallet[]; cards: Card[] }>>("/")
      .then(this.handleResponse)
      .catch(this.handleError);
  }

  public async byIdentifier(identifier: string) {
    return this._http
      .get<ApiResponse<Wallet>>(`/${identifier}`)
      .then(this.handleResponse)
      .catch(this.handleError);
  }

  public async transactionsFor(
    identifier: string,
    paginationMeta?: PaginatedRequest,
  ) {
    return this._http
      .get<ApiResponse<WalletTransaction[]>>(`/${identifier}/transactions`, {
        params: paginationMeta,
      })
      .then(this.handleResponse)
      .catch(this.handleError);
  }

  public async transferAccounts() {
    return this._http
      .get<ApiResponse<{ from_accounts: Wallet[]; to_accounts: Wallet[] }>>(
        "/transfer_accounts",
      )
      .then(this.handleResponse)
      .catch(this.handleError);
  }

  public async transferFunds(data: {
    from_account: string;
    to_account: string;
    amount: number;
  }) {
    return this._http
      .post("/transfers", data)
      .then(this.handleResponse)
      .catch(this.handleError);
  }
}

export const financesRepository = new FinancesRepository();
