import axios from "axios";

import { endpoints } from "constants/apiEndpoints";

export type MerchantInfoResponse = {
  currentMerchant: {
    address: string | null;
    city: string | null;
    country: string | null;
    eligibleForExpeditedSettlements: boolean;
    expectSpendOnCardsMonthly: string | null;
    identifier: string;
    kybCurrentStep: string;
    name: string;
    cardAgreeTermsAgreementAt: string | null;
    premiumAccess: boolean;
    status: string;
    trialAccess: boolean | null;
    trialRemainingDays: number;
    brandingSettings: {
      currentLogo: string | null;
      currentBrandColor: string;
      currentAccentColor: string;
    };
    planSelected: boolean;
    isMerchantVerified: boolean;
    subscriptionRoot: {
      hasBasic: boolean;
      hasPremiumBadge: boolean;
    };
  };
  currentUser: {
    email: string;
    firstName: string;
    identifier: string;
    lastName: string;
    middleName: string | null;
    nameInitials: string;
    roles: [{ name: string; id: string | number }];
    phoneNumber: string | null;
    phoneVerified: boolean;
  };
  featureToggles: {
    cardsFeatureEnabled: boolean;
    expensesFeatureEnabled: boolean;
  };
  platform: string | null;
};

export const fetchMerchantInfo = async (): Promise<MerchantInfoResponse> => {
  const response = await axios.get(endpoints.MANAGE.API.MERCHANT.INFO);

  return response.data;
};
