import { posLinkIdentifier } from "features/POS/shared/utils/posLinkIdentifier";

export const endpoints = {
  MANAGE: {
    API: {
      ACCOUNTS: {
        INDEX: `/manage/api/v1/accounts`,
        SLACK_DEEP_LINK: "/manage/api/v1/accounts/slack_deep_link",
        TURN_OFF_VERIFICATION_SUCCESS_MODAL:
          "/manage/api/v1/accounts/disable_verified_popup",
        VERIFY_PHONE_NUMBER: "/manage/api/v1/accounts/verify_phone_number",
      },
      ACCOUNTING_INTEGRATIONS: {
        BULK_UPDATE_EXPENSE_ACCOUNTS: `/manage/api/v1/accounting_integrations/bulk_update_expense_accounts`,
        BULK_UPDATE_PAYMENT_ACCOUNTS: `/manage/api/v1/accounting_integrations/bulk_update_payment_accounts`,
        BULK_UPDATE_TAX_RATES: `/manage/api/v1/accounting_integrations/bulk_update_tax_rates`,
        BULK_UPDATE_VENDORS: `/manage/api/v1/accounting_integrations/bulk_update_vendors`,
        CREATE_INVITE_LINK: `/manage/api/v1/accounting_integrations/create_invite_link`,
        EXPENSE_ACCOUNTS: `/manage/api/v1/accounting_integrations/expense_accounts`,
        INDEX: `/manage/api/v1/accounting_integrations`,
        PAYMENT_ACCOUNTS: `/manage/api/v1/accounting_integrations/payment_accounts`,
        TAX_RATES: `/manage/api/v1/accounting_integrations/tax_rates`,
        VENDORS: `/manage/api/v1/accounting_integrations/vendors`,
      },
      BALANCE_TRANSACTIONS: {
        INDEX: (identifier: string) =>
          `/manage/api/v1/balance_transactions/${identifier}`,
      },
      CARDS: {
        ACCOUNT_DETAIL: "/manage/api/v1/cards/account",
        ALL_TRANSACTIONS_CSV: "/manage/api/v1/cards/transactions.csv",
        ALL_TRANSACTIONS_JSON: "/manage/api/v1/cards/transactions.json",
        CARD_TRANSACTIONS_CSV: (identifier: string) =>
          `/manage/api/v1/cards/${identifier}/transactions.csv`,
        CARD_TRANSACTIONS_JSON: (identifier: string) =>
          `/manage/api/v1/cards/${identifier}/transactions.json`,
        CHANGE_STATUS: (identifier: string) =>
          `/manage/api/v1/cards/${identifier}/change_status`,
        CHANGE_INFO: (identifier: string) =>
          `/manage/api/v1/cards/${identifier}`,
        DETAIL: (identifier: string) => `/manage/api/v1/cards/${identifier}`,
        FEE_INFO: "/manage/api/v1/cards/fee_info",
        INDEX: "/manage/api/v1/cards",
        KYB_STATUS: "/manage/api/v1/cards/kyb_status",
        PENDING_ACTIVATION_CARDS:
          "/manage/api/v1/cards/pending_activation_cards",
        PCI_TOKEN: (identifier: string) =>
          `/manage/api/v1/cards/${identifier}/generate_lut.json`,
        SECRET_DATA: (identifier: string) =>
          `/manage/api/v1/cards/${identifier}/secret_data`,
        SPEND_LIMITS: (identifier: string) =>
          `/manage/api/v1/cards/${identifier}/velocity_limits`,
        SPEND_LIMIT_DETAIL: (cIdentifier: string, limitIdentifier: string) =>
          ` /manage/api/v1/cards/${cIdentifier}/velocity_limits/${limitIdentifier}`,
        SPEND_LIMIT_EDIT: (cIdentifier: string, limitIdentifier: string) =>
          `/manage/api/v1/cards/${cIdentifier}/velocity_limits/${limitIdentifier}`,
        SPEND_LIMIT_DEACTIVATE: (cIdentifier, limitIdentifier) =>
          `/manage/api/v1/cards/${cIdentifier}/velocity_limits/${limitIdentifier}/deactivate`,
        TERMS_AGREEMENT: "/manage/api/v1/cards/agree_terms_agreement",
        TOP_UP: "/manage/api/v1/cards/topup",
        TRANSACTIONS: {
          DETAIL: (cIdentifier: string, tIdentifier: string) =>
            `/manage/api/v1/cards/${cIdentifier}/transactions/${tIdentifier}`,
        },
      },
      CARDS_VCC: {
        DETAIL: "/api/v2/cards",
        OTP_REQUIRED: "/api/v2/cards/otp_required",
        PCI_TOKEN: "/api/v2/cards/generate_lut",
        RESEND_OTP: "/api/v2/cards/send_vcc_otp",
      },
      CREDIT_ACCOUNTS: {
        ACTIVITIES: `/manage/api/v1/credit_accounts/activities`,
        INDEX: `/manage/api/v1/credit_accounts`,
        REPAYMENTS: `/manage/api/v1/credit_accounts/repayment`,
        REPAYMENTS_TRANSACTIONS: `/manage/api/v1/credit_accounts/repayment_transactions`,
      },
      EXPENSES: {
        ADD_CATEGORIES: "/manage/api/v1/expense_categories/bulk_create",
        BULK_DOWNLOAD: "/manage/api/v1/expenses/bulk_download.zip",
        BULK_EDIT: "/manage/api/v1/expenses/bulk_update",
        CATEGORIES: "/manage/api/v1/expense_categories",
        DELETE_CATEGORIES: "/manage/api/v1/expense_categories/bulk_destroy",
        ENABLE: "/manage/api/v1/expenses/enable_feature",
        EXPENSES_CSV: "/manage/api/v1/expenses.csv",
        INDEX: "/manage/api/v1/expenses",
        SYNC_WITH_ACCOUNTING_INTEGRATION:
          "/manage/api/v1/expenses/sync_accounting_integration",
        VALIDATE_CATEGORIES: "/manage/api/v1/expense_categories/validate",
        ATTACHMENTS: (identifier: string) =>
          `/manage/api/v1/expenses/${identifier}/attachments`,
        DETAIL: (identifier: string) => `/manage/api/v1/expenses/${identifier}`,
        EDIT: (identifier: string) => `/manage/api/v1/expenses/${identifier}`,
        SUGGESTED_DATA: (identifier: string) =>
          `/manage/api/v1/expenses/${identifier}/suggested_data`,
      },
      GLOBAL_SEARCH: "/manage/api/v1/global_search",
      LINKS: {
        ACTIVATE: (identifier: string) =>
          `/manage/api/v1/links/${identifier}/activate`,
      },
      MERCHANT: {
        INDEX: "/manage/api/v1/merchants",
        INFO: "/manage/api/v1/merchants/info",
        PLAN_DETAILS: "/manage/api/v1/merchants/plan_detail",
        TOP_UP_BANK_DETAILS: "/manage/api/v1/merchants/top_up_bank_details",
      },
      PAYMENTS: {
        INDEX: "/manage/payments",
        CAPTURE_HELD_PAYMENT: (identifier: string) =>
          `/manage/api/v1/charges/${identifier}/captures`,
        DETAIL: (identifier: string) => `/manage/api/v1/charges/${identifier}`,
        DOWNLOAD_REFUND_RECEIPTS: (identifier: string) =>
          `/manage/api/v1/charges/${identifier}/download_refund_invoices.zip`,
        RESEND_RECEIPT: (identifier: string) =>
          `/manage/api/v1/charges/${identifier}/resend_receipt_to_customer`,
        REVERSE_HELD_PAYMENT: (identifier: string) =>
          `/manage/api/v1/charges/${identifier}/reverses`,
        SEND_REFUND_RECEIPT: (identifier: string) =>
          `/manage/api/v1/charges/${identifier}/send_refund_invoices`,
        DISPUTES: {
          INDEX: "/manage/api/v1/disputes",
          CLOSE_DISPUTE: (identifier: string) =>
            `/manage/api/v1/disputes/${identifier}/close_dispute`,
          DETAIL: (identifier: string) =>
            `/manage/api/v1/disputes/${identifier}`,
          DISPUTE_PROOF: (identifier: string) =>
            `/manage/api/v1/disputes/${identifier}/dispute_proofs`,
          SUBMIT_DISPUTE_PROOFS: (identifier: string) =>
            `/manage/api/v1/disputes/${identifier}/submit_dispute_proofs`,
        },
      },
      POS: {
        UPDATE_AMOUNT: (identifier: string | undefined) =>
          `/manage/links/${posLinkIdentifier(identifier)}/update_amount`,
      },
      SETTLEMENTS: {
        EXPORT: "/manage/settlements/export",
      },
      SETTINGS: {
        ACCOUNTING: {
          REFRESH_INFO: "/manage/api/v1/accounting_integrations/refresh_data",
        },
        BANK: {
          ACCOUNT_HOLDER_NAMES:
            "/manage/api/v1/bank_accounts/account_holder_names",
          FIND_BANK_NAME: "/manage/api/v1/bank_accounts/find_bank",
          GENERATE_OTP: "/manage/api/v1/users/generate_otp",
          UPDATE_BANK_ACCOUNT: "/manage/api/v1/bank_accounts",
        },
        BUSINESS: {
          SHOW_DETAILS: "/manage/api/v1/business_details/show",
          UPDATE_DETAILS: "/manage/api/v1/business_details/update",
        },
        TEAM: {
          USERS: "/manage/api/v1/operators",
          ROLES: "/manage/api/v1/roles",
          USER_DETAIL: (identifier: string) =>
            `/manage/api/v1/operators/${identifier}`,
          INVITE_USER: "/manage/api/v1/operators",
          UPDATE_USER: (identifier: string) =>
            `/manage/api/v1/operators/${identifier}`,
          RESEND_INVITE_MAIL: (identifier: string) =>
            `/manage/api/v1/operators/${identifier}/resend_invite_mail`,
          REMOVE_USER: (identifier: string) =>
            `/manage/api/v1/operators/${identifier}`,
        },
      },
      ULTRA: {
        BALANCE_SUMMARY: "/manage/api/v1/ultra_balances/summary",
      },
      USERS: {
        CARDS: (identifier: string) =>
          `/manage/api/v1/users/${identifier}/cards`,
        DETAIL: (identifier: string) => `/manage/api/v1/users/${identifier}`,
        INDEX: "/manage/api/v1/users",
        BENEFICIAL_OWNERS: "/manage/api/v1/users/beneficial_owners",
        CARD_HOLDERS: {
          INDEX: "/manage/api/v1/users/card_holders",
          EDIT: (identifier) =>
            `/manage/api/v1/users/${identifier}/card_holders`,
        },
        UPDATE_ADDRESSES: "/manage/api/v1/users/update_addresses",
        UPDATE_STATUS: (identifier: string) =>
          `/manage/api/v1/users/${identifier}/change_status`,
        ALL_KYC_STATUSES: "/manage/api/v1/users/kyc_status",
        KYC_STATUS: (identifier: string) =>
          `/manage/api/v1/users/${identifier}/kyc_status_detail`,
      },
      DISBURSEMENTS: {
        CREATE: "/manage/api/v1/payouts",
        GENERATE_OTP: "/manage/api/v1/users/generate_otp",
        INDEX: "/manage/api/v1/payouts",
        UPLOAD: "/manage/api/v1/payouts/upload",
        EXPORT: "/manage/api/v1/payouts/export",
        DETAIL: (identifier: string) => `/manage/api/v1/payouts/${identifier}`,
      },
      INVOICES: {
        INDEX: "/manage/api/v1/invoices",
        DETAIL: (identifier: string) => `/manage/api/v1/invoices/${identifier}`,
      },
      VERIFICATION: {
        COMPLETE_OPTIONAL_STEP:
          "/manage/api/v1/onboarding/complete_optional_step",
        CREATE_BUSINESS_OWNER: "/manage/api/v1/onboarding/business_owner",
        SUBMIT: "/manage/api/v1/onboarding/submit_verification",
      },
      WALLETS: {
        INDEX: "/manage/api/v1/finances",
        DETAIL: (identifier: string) => `/manage/api/v1/finances/${identifier}`,
        TRANSACTIONS: (identifier: string) =>
          `/manage/api/v1/finances/${identifier}/transactions`,
      },
    },
  },
};
