import EmptyStateIllustration from "assets/illustrations/payments-empty-state.svg";
import { EmptyState } from "components/EmptyState/EmptyStateV2";

export const TableEmptyState = () => {
  return (
    <EmptyState
      data-testid="empty-state-wrapper"
      imgSrc={EmptyStateIllustration}
      title="No activity to show at the moment"
      info="Once you start utilizing your credit, you'll be able to see your credit history and activity here."
    />
  );
};
