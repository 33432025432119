import { type MerchantStatus, MerchantStatuses } from "types/MerchantInfo";

export type DisplayPaywallProps = {
  status: MerchantStatus;
};

export function displayPaywall({ status }: DisplayPaywallProps) {
  const paywallForStatuses: MerchantStatus[] = [
    MerchantStatuses.pending_verification,
    MerchantStatuses.not_complete,
  ];

  return paywallForStatuses.includes(MerchantStatuses[status]);
}
