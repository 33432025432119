import { Divider } from "@mui/material";
import { useContext, useMemo } from "react";
import { useDispatch } from "react-redux";

import { TableRecord } from "components/Table/TableRecord";
import Table from "components/Table/V2/TableV2";
import Pagination from "components/ui/Pagination/PaginationV2";
import { TableEmptyState } from "./TableEmptyState";

import { useScreenDimension } from "hooks/useScreenDimension";
import { useAppSelector } from "store";
import { useCreditCardActivitiesTable } from "../../../hooks/tables/useCreditCardActivitiesTable";

import { SideSheetContext } from "design_system/Sidesheet/SideSheetContext";

import { queryClient } from "config/reactQueryClient";
import { setRecordDetail } from "reducers/redux/TableReducer/tableReducer";
import { getTapixAvatar } from "utils/tapixAvatarUtil";
import { repaymentsStatusMapping } from "../../../constants/creditRepayments";

export const CreditActivitiesTable = ({ children }) => {
  /* ---------------------------------
   *  Context APIS & CACHES
   * ---------------------------------
   */
  const { isSideSheetOpen, openSideSheet } = useContext(SideSheetContext);
  const activities: any = queryClient.getQueryData(["getCreditCardActivities"]);

  /* ---------------------------------
   *  Hooks
   * ---------------------------------
   */
  const dispatch = useDispatch();
  const { COLUMNS, handleSetPage } = useCreditCardActivitiesTable();
  const { isMobile } = useScreenDimension();

  const { recordDetail } = useAppSelector(
    (state) => state.table.creditActivities,
  );

  /* ---------------------------------
   *  Constants
   * ---------------------------------
   */
  const activitiesData = activities?.data || [];
  const columns = useMemo(() => COLUMNS, [JSON.stringify(recordDetail)]);
  const tableData = useMemo(() => activitiesData, [activitiesData]);
  const isEmptyTable = activitiesData.length === 0;
  const isTransactionSidesheet = !recordDetail?.cardIdentifier;

  const paginationMeta = {
    ...activities?.paginationMeta,
    itemName: "activities",
  };

  /* ---------------------------------
   *  Helpers
   * ---------------------------------
   */
  const handleRecordClick = (recordDetail) => {
    dispatch(
      setRecordDetail({
        tableName: "creditActivities",
        recordDetail,
      }),
    );
    openSideSheet();
  };

  // Laptop and Tablet View
  if (!isMobile) {
    return (
      <div className="mt-24 flex w-full justify-center px-40">
        <Table
          name="credit-card-activities-table"
          columns={columns}
          data={tableData}
          paginationMeta={paginationMeta}
          onSetPage={handleSetPage}
          tableClass="table"
          headerRowClass="bg-black-25"
          headerCellClass="w-auto"
          onClick={handleRecordClick}
          rowProps={{
            selectable: false,
            selectedRows: [],
            setSelectedRows: () => {},
          }}
          emptyState={<TableEmptyState />}
        />
        {children(isSideSheetOpen, isTransactionSidesheet, recordDetail)}
      </div>
    );
  }
  return isEmptyTable ? (
    <TableEmptyState />
  ) : (
    <div className="mt-24">
      {activitiesData.map((activity: any, index: number) => {
        const avatar = getTapixAvatar(
          activity?.tapixShop || "",
          activity?.merchantName || "",
        );
        return (
          <TableRecord
            key={activity.identifier + index}
            onClick={() => handleRecordClick(activity)}
          >
            {avatar.isIcon && avatar.Icon && (
              <TableRecord.Avatar
                size="xl"
                isIcon
                Icon={avatar.Icon}
                tagProps={{
                  iconOnly: true,
                  Icon: repaymentsStatusMapping[activity.status]?.Icon,
                  color: repaymentsStatusMapping[activity.status]?.color,
                }}
              />
            )}
            {!avatar.isIcon && avatar.url && (
              <TableRecord.Avatar
                isIcon={false}
                size="xl"
                url={avatar.url}
                tagProps={{
                  iconOnly: true,
                  Icon: repaymentsStatusMapping[activity.status]?.Icon,
                  color: repaymentsStatusMapping[activity.status]?.color,
                }}
              />
            )}
            <TableRecord.Column1
              label={activity.merchantName}
              supportingText={[activity.createdAt, activity.cardName]}
            />
            <TableRecord.Column2 label={activity.billingAmountFormatted} />
          </TableRecord>
        );
      })}
      <Divider />
      <div className="disbursements__pagination px-16">
        <Pagination
          itemName={paginationMeta.itemName}
          meta={paginationMeta}
          onSetPage={handleSetPage}
        />
      </div>
      {children(isSideSheetOpen, isTransactionSidesheet)}
    </div>
  );
};
