import { useByIdentifier } from "repositories/finances/useByIdentifier";
import { useGetFinances } from "repositories/finances/useGetFinances";
import { useTransactionsFor } from "repositories/finances/useTransactionsFor";
import { useTransferAccounts } from "repositories/finances/useTransferAccounts";
import { useTransferFunds } from "repositories/finances/useTransferFunds";

export * from "./finances.repository";

export const financesHooks = {
  useByIdentifier,
  useGetFinances,
  useTransactionsFor,
  useTransferAccounts,
  useTransferFunds,
};
