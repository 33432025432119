import type {
  AxiosAdapter,
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import axios from "axios";
import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer,
} from "axios-extensions";
import qs from "qs";
import ReactOnRails from "react-on-rails";

import { routes } from "constants/routes";
import { SentryLoggingService } from "init/SentryLoggingService";

import type {
  ApiResponse,
  ErrorResponse,
  PaginatedApiResponse,
  RepositoryResult,
  Success,
} from "repositories/_base/repository.types";
import { handleAxiosErrorMessage, isAxiosError } from "./shared";

export type RepositoryOptions = {
  gateway: string;
  baseURL?: string;
  noAuth?: boolean;
};

export abstract class Repository {
  /**
   * Axios instance
   */
  protected _http: AxiosInstance;

  /**
   * Base URL for all requests
   */
  private _baseURL = "/manage/api/v1";

  constructor(options: RepositoryOptions) {
    this._http = this._init(options);

    this._http.interceptors.response.use(
      this._interceptResponse.bind(this),
      this._interceptError.bind(this),
    );

    if (!options.noAuth) {
      this._http.interceptors.request.use(this._addCSRFToken.bind(this));
    }
  }

  /**
   * Check if the data is a success
   */
  protected isSuccess<T>(data: RepositoryResult<T>): data is Success<T> {
    return data.success;
  }

  /**
   * Check if the data is an error
   */
  protected isError<T>(data: RepositoryResult<T>): data is ErrorResponse {
    return !data.success;
  }

  /**
   * Private Section
   */

  private _init(options: RepositoryOptions) {
    // If no override given, use default one.
    const baseURL = options.baseURL || this._baseURL;

    return axios.create({
      baseURL: `${baseURL}${options.gateway}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 30000,
      adapter: throttleAdapterEnhancer(
        cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
          cacheFlag: "useCache",
        }),
      ),
    });
  }

  private _addCSRFToken(config: AxiosRequestConfig) {
    config.url = `${config.url}?${qs.stringify({
      authenticity_token: ReactOnRails.authenticityToken(),
    })}`;
    return config;
  }

  private _interceptResponse(response: AxiosResponse) {
    return response;
  }

  private _interceptError(error: any) {
    this._handleInterceptorError(error);
    // If API returns 403 status code, redirect users to forbidden page
    if (error?.response?.status === 403) {
      window.location.href = routes.MANAGE.ERRORS.FORBIDDEN();
    }

    if (error?.response?.status === 401) {
      window.location.href = routes.MANAGE.SESSIONS.LOGIN();
    }

    return Promise.reject(error);
  }

  private _getFeatureName() {
    return this.constructor.name;
  }

  private _handleInterceptorError(error: AxiosError) {
    SentryLoggingService.captureException(error?.message, {
      feature: `Repository: ${this._getFeatureName()}`,
      function: "Interceptor Error",
      data: JSON.stringify(error?.response?.data),
    });
  }

  public sentryLogError({
    error,
    feature,
    functionName,
    data,
  }: {
    error: string;
    feature: string;
    functionName: string;
    data: string;
  }) {
    SentryLoggingService.captureException(error, {
      feature,
      function: functionName,
      data,
    });
  }

  protected handleResponseWithoutData<T = unknown>(
    data: AxiosResponse<T>,
  ): Success<T> {
    return {
      success: true,
      data: data.data as T,
    };
  }

  protected handleResponse<T = unknown>(
    data: AxiosResponse<ApiResponse<T>>,
  ): Success<T> {
    return {
      success: true,
      data: data.data.data as T,
      paginationMeta: data.data?.paginationMeta,
    };
  }

  protected handlePaginatedResponse<T = unknown>(
    data: AxiosResponse<PaginatedApiResponse<T>>,
  ): Success<T> {
    return {
      success: true,
      data: data.data as T,
      paginationMeta: data.data?.paginationMeta,
    };
  }

  protected handleError(error: unknown): ErrorResponse {
    const statusCode = isAxiosError(error) ? error.response?.status : 500;
    const message = handleAxiosErrorMessage(error);

    return {
      success: false,
      error: {
        key: message.key,
        message: message.message,
        statusCode,
      },
      data: null,
    };
  }
}
