import { useMutation } from "@tanstack/react-query";
import { financesRepository } from "repositories/finances/finances.repository";
import { FINANCES_KEYS } from "repositories/finances/keys";

/**
 * Used to transfer funds from one account to another
 *
 */
export const useTransferFunds = () => {
  return useMutation({
    mutationKey: FINANCES_KEYS.TRANSFER_FUNDS,
    mutationFn: async (data: {
      from_account: string;
      to_account: string;
      amount: number;
    }) => {
      const response = await financesRepository.transferFunds(data);

      if (!response.success && "error" in response) {
        financesRepository.sentryLogError({
          error: response.error.message,
          feature: "finances",
          functionName: "useTransferFunds",
          data: JSON.stringify(response.error),
        });

        return response;
      }

      return response;
    },
  });
};
