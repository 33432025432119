import type { TPlanFeatures } from "./types";

export const plansList = [
  {
    id: "growth",
    name: "Growth",
    amount: "AED 0",
    fees: {
      uae: "2.9%",
      intl: "3.4%",
      fixed: "AED 1",
    },
  },
  {
    id: "premium",
    name: "Premium",
    amount: "AED 99",
    fees: {
      uae: "2.7%",
      intl: "3.2%",
      fixed: "AED 0.8",
    },
  },
];

export const allFeatures: TPlanFeatures[] = [
  {
    id: "receiving-payments",
    title: "Receiving payments",
    list: [
      {
        feature: {
          text: "Transaction fee on UAE cards",
          subtext: "When charged in AED",
          growth: {
            text: "2.9% + AED 1",
          },
          premium: {
            text: "2.7% + AED 0.8",
          },
        },
      },
      {
        feature: {
          text: "Transaction fee on international cards",
          subtext: "When charged in AED",
          growth: {
            text: "+0.5%",
          },
          premium: {
            text: "+0.5%",
          },
        },
      },
      {
        feature: {
          text: "Receive payments in other currencies",
          growth: {
            text: "+2%",
          },
          premium: {
            text: "+1.5%",
          },
        },
      },
      {
        feature: {
          text: "Settlement period",
          growth: {
            text: "5 business days",
          },
          premium: {
            text: "5 business days",
          },
        },
      },
      {
        feature: {
          text: "Same-day settlements",
          subtext: "Optional",
          growth: {
            text: "+0.75%",
          },
          premium: {
            text: "+0.5%",
          },
        },
      },
      {
        feature: {
          text: "Failed payment attempts",
          growth: {
            text: "Free",
          },
          premium: {
            text: "Free",
          },
        },
      },
      {
        feature: {
          text: "Placing holds on cards",
          subtext: "For security deposits",
          growth: {
            text: "AED 1",
          },
          premium: {
            text: "AED 0.80",
          },
        },
      },
      {
        feature: {
          text: "Subscription payments",
          growth: {
            text: "yes",
          },
          premium: {
            text: "yes",
          },
        },
      },
    ],
  },
  {
    id: "spend-management",
    title: "Spend management",
    list: [
      {
        feature: {
          text: "Corporate cards",
          subtext: "Physical and virtual",
          growth: {
            text: "Unlimited free",
          },
          premium: {
            text: "Unlimited free",
          },
        },
      },
      {
        feature: {
          text: "Cashback",
          subtext: "Earn cashback on all your non-AED spends.",
          growth: {
            text: "1%",
          },
          premium: {
            text: "2%",
          },
        },
      },
      {
        feature: {
          text: "Credit cards",
          growth: {
            text: "Contact sales",
          },
          premium: {
            text: "Contact sales",
          },
        },
      },
      {
        feature: {
          text: "ATM withdrawals in UAE",
          growth: {
            text: "AED 5",
          },
          premium: {
            text: "Free",
          },
        },
      },
      {
        feature: {
          text: "ATM withdrawals internationally",
          growth: {
            text: "AED 20",
          },
          premium: {
            text: "AED 20",
          },
        },
      },
      {
        feature: {
          text: "Spend limits and controls",
          growth: {
            text: "yes",
          },
          premium: {
            text: "yes",
          },
        },
      },
      {
        feature: {
          text: "Expense management and approval workflows",
          growth: {
            text: "yes",
          },
          premium: {
            text: "yes",
          },
        },
      },
      {
        feature: {
          text: "Expense receipt upload via WhatsApp",
          growth: {
            text: "yes",
          },
          premium: {
            text: "yes",
          },
        },
      },
      {
        feature: {
          text: "Vendor bank transfers",
          subtext: "To business and individuals",
          growth: {
            text: "AED 5 per payout",
          },
          premium: {
            text: "AED 5 per payout",
          },
        },
      },
    ],
  },
  {
    id: "integrations",
    title: "Integrations",
    list: [
      {
        feature: {
          text: "Payment APIs",
          growth: {
            text: "yes",
          },
          premium: {
            text: "yes",
          },
        },
      },
      {
        feature: {
          text: "Recurring payment APIs",
          growth: {
            text: "yes",
          },
          premium: {
            text: "yes",
          },
        },
      },
      {
        feature: {
          text: "E-commerce platform integrations",
          growth: {
            text: "yes",
          },
          premium: {
            text: "yes",
          },
        },
      },
      {
        feature: {
          text: "Card APIs",
          growth: {
            text: "yes",
          },
          premium: {
            text: "yes",
          },
        },
      },
      {
        feature: {
          text: "Marketplace payout APIs",
          growth: {
            text: "yes",
          },
          premium: {
            text: "yes",
          },
        },
      },
      {
        feature: {
          text: "Accounting software integrations",
          growth: {
            text: "yes",
          },
          premium: {
            text: "yes",
          },
        },
      },
      {
        feature: {
          text: "VCC APIs",
          subtext:
            "Virtual Corporate Cards for DMCs and tour operators to streamline vendor payments.",
          growth: {
            text: "Contact sales",
          },
          premium: {
            text: "Contact sales",
          },
        },
      },
    ],
  },
  {
    id: "additional-services",
    title: "Additional services and features",
    list: [
      {
        feature: {
          text: "Dedicated customer succes manager",
          growth: {
            text: "Contact sales",
          },
          premium: {
            text: "Contact sales",
          },
        },
      },
      {
        feature: {
          text: "Dedicated Slack channel for support",
          growth: {
            text: "Contact sales",
          },
          premium: {
            text: "Contact sales",
          },
        },
      },
      {
        feature: {
          text: "Chargeback and refund management",
          growth: {
            text: "yes",
          },
          premium: {
            text: "yes",
          },
        },
      },
      {
        feature: {
          text: "VAT-enabled invoicing",
          growth: {
            text: "yes",
          },
          premium: {
            text: "yes",
          },
        },
      },
      {
        feature: {
          text: "User access and defined roles",
          growth: {
            text: "yes",
          },
          premium: {
            text: "yes",
          },
        },
      },
      {
        feature: {
          text: "Payment links via WhatsApp bot",
          growth: {
            text: "yes",
          },
          premium: {
            text: "yes",
          },
        },
      },
      {
        feature: {
          text: "Branded payment links",
          growth: {
            text: "--",
          },
          premium: {
            text: "yes",
          },
        },
      },
    ],
  },
];
