import { useQuery } from "@tanstack/react-query";

import { FINANCES_KEYS } from "repositories/finances/keys";
import { financesRepository } from "./finances.repository";

export const useByIdentifier = (identifier: string) => {
  return useQuery({
    queryKey: FINANCES_KEYS.BY_IDENTIFIER(identifier),
    queryFn: async () => {
      const response = await financesRepository.byIdentifier(identifier);

      if (!response.success && "error" in response) {
        financesRepository.sentryLogError({
          error: response.error.message,
          feature: "finances",
          functionName: "useByIdentifier",
          data: JSON.stringify(response.error),
        });

        return response;
      }

      return response;
    },
  });
};
