import { useNavigate } from "react-router-dom";
import { cn } from "utils/classNames";

import { UaeFlag } from "design_system/Illustrations/CurrencyFlags";
import { MasterHeader } from "design_system/MasterHeader";

import { TransferFundsButton } from "features/Wallet/WalletV2/components/TransferFundsButton";
import { useScreenDimension } from "hooks/useScreenDimension";

import routes from "constants/routes";
import { TransferFundsSuccess } from "features/Wallet/WalletV2/components/TransferFundsSuccess";
import { WalletType } from "types/Wallet";
import type { WalletV2HeaderProps } from "../typeDefs";

export const PageHeader = ({
  isTotalBalanceNegative,
  totalBalance,
}: WalletV2HeaderProps) => {
  const navigate = useNavigate();
  const { isMobile } = useScreenDimension();

  const handleBackButtonClick = () => {
    navigate(routes.MANAGE.WALLET.INDEX());
  };

  return (
    <>
      <TransferFundsSuccess />
      <MasterHeader onBack={handleBackButtonClick}>
        <MasterHeader.Image>
          <UaeFlag size={isMobile ? "48" : "56"} />
        </MasterHeader.Image>
        <MasterHeader.Actions>
          <TransferFundsButton fromAccount={WalletType.MAIN_WALLET} />
        </MasterHeader.Actions>
        <MasterHeader.Label>UAE Dirham</MasterHeader.Label>
        <MasterHeader.Title
          className={cn({
            "text-error-1": isTotalBalanceNegative,
          })}
        >
          {totalBalance}
        </MasterHeader.Title>
      </MasterHeader>
    </>
  );
};
