import { memo } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Loader } from "design_system/Loader";
import SubscriptionListContainer from "./SubscriptionListContainer";
import SubscriptionPaywall from "./SubscriptionPaywall";

import { useMerchantInfo } from "repositories/merchant/useMerchantInfo";
import { displayPaywall } from "utils/displayPaywall";

export const SubscriptionContainer = memo(() => {
  const { data, isLoading } = useMerchantInfo();

  const currentMerchant = data?.currentMerchant;

  const status = currentMerchant?.status;
  const isMerchantVerified = currentMerchant?.status === "verified";

  const hasPremiumBadge =
    currentMerchant?.subscriptionRoot?.hasPremiumBadge === true;

  const hasBasic =
    currentMerchant?.subscriptionRoot?.hasBasic ||
    (currentMerchant?.premiumAccess === false && isMerchantVerified);

  const showPaywall = displayPaywall({ status });

  if (isLoading) {
    return <Loader variant="fullPage" />;
  }

  if (showPaywall) {
    return <SubscriptionPaywall status={status} />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/manage/subscriptions"
          element={
            <SubscriptionListContainer
              hasBasic={hasBasic}
              hasPremiumBadge={hasPremiumBadge}
              isMerchantVerified={isMerchantVerified}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
});
