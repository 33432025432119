import { createSearchParams, useLocation } from "react-router-dom";

import { routes } from "constants/routes";

import { Button } from "design_system/Button";
import { SwapHorizontal } from "design_system/Icons";
import { cn } from "utils/classNames";

export type TransferFundsButtonProps = {
  className?: string;
  fromAccount?: string;
};

export function TransferFundsButton({
  className,
  fromAccount,
}: TransferFundsButtonProps) {
  const { pathname } = useLocation();

  const searchParams = createSearchParams({
    onSuccessUrl: pathname,
    ...(fromAccount ? { fromAccount } : {}),
  }).toString();

  const transferUrl = `${routes.MANAGE.WALLET.TRANSFER()}?${searchParams}`;

  return (
    <a href={transferUrl} className={cn(className)}>
      <Button
        label="Transfer"
        Icon={SwapHorizontal}
        size="md"
        variant="filled"
      />
    </a>
  );
}
