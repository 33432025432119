import { Button } from "design_system/Button";
import { Add, Diamond } from "design_system/Icons";
import { MasterHeader } from "design_system/MasterHeader";
import { Tag } from "design_system/Tag";

import routes from "constants/routes";
import { isAppPlatform } from "helpers/isAppPlatform";

interface SusbcriptionsPageHeaderProps {
  hasBasic: boolean;
  hasPremiumBadge: boolean;
  isMerchantVerified: boolean;
}

export const PageHeader = ({
  hasBasic,
  hasPremiumBadge,
  isMerchantVerified,
}: SusbcriptionsPageHeaderProps) => {
  const handleGoToRecurringPayment = () => {
    window.location.href = routes.MANAGE.SUBSCRIPTIONS.NEW();
  };

  if (isAppPlatform()) {
    return <></>;
  }

  return (
    <MasterHeader>
      <MasterHeader.Title>Recurring payments</MasterHeader.Title>
      {hasPremiumBadge && (
        <MasterHeader.Tag>
          <Tag
            size="md"
            color="black"
            label="Premium trial"
            iconPosition="left"
            Icon={Diamond}
          />
        </MasterHeader.Tag>
      )}

      {isMerchantVerified && (
        <MasterHeader.Actions>
          <Button
            variant="filled"
            size="md"
            label="New recurring payment"
            iconPosition="left"
            Icon={Add}
            onClick={handleGoToRecurringPayment}
          />
        </MasterHeader.Actions>
      )}
    </MasterHeader>
  );
};
