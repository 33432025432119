import DOMPurify from "dompurify";
import { mixed, string } from "yup";

/**
 * Reusable function to create a Yup string validation
 * that disallows URLs in the input.
 *
 * @param {string} errorMessage - The error message to display if validation fails.
 * @returns {Yup.StringSchema} A Yup string schema with the no-URL validation applied.
 */
export const stringWithoutURL = (errorMessage = "URLs are not allowed") => {
  const urlPattern = /https?:\/\/|www\./i; // Matches http://, https://, or www.
  return string().test("no-url", errorMessage, (value) => {
    if (!value) return true; // Allow empty strings if not required
    return !urlPattern.test(value); // Return false if the string contains a URL
  });
};

/**
 * Reusable function to create a Yup string validation
 * that disallows HTML.
 *
 * @param errorMessage - The error message to display if validation fails.
 * @returns A Yup string schema with the HTML validation applied.
 */
export const noHTMLDom = (errorMessage = "HTML is not allowed") =>
  string().test("no-html", errorMessage, (value) => {
    if (!value) return true; // Allow empty strings if not required

    const sanitized = DOMPurify.sanitize(value, {
      ALLOWED_TAGS: [], // Don't allow any HTML tags
      ALLOWED_ATTR: [], // Don't allow any attributes
    });

    // If the sanitized value is different from the original, HTML was present
    return sanitized === value;
  });

/**
 * Reusable function to create a Yup string validation
 * that limits the length of the input.
 *
 * @param min - The minimum length of the input.
 * @param max - The maximum length of the input.
 * @param errorMessage - The error message to display if validation fails.
 * @returns A Yup string schema with the length validation applied.
 */
export const limitedLengthString = (
  min: number,
  max: number,
  errorMessage = `Input must be between ${min} and ${max} characters`,
) =>
  string()
    .min(min, `Must be at least ${min} characters`)
    .max(max, errorMessage);

/**
 * Reusable function to create a Yup string validation
 * that allows only alphanumeric characters.
 *
 * @param errorMessage - The error message to display if validation fails.
 * @returns A Yup string schema with the alphanumeric validation applied.
 */
export const alphanumericString = (
  errorMessage = "Only alphanumeric characters are allowed",
) => string().matches(/^[a-zA-Z0-9]*$/, errorMessage);

/**
 * Reusable function to create a Yup string validation
 * that disallows numbers.
 *
 * @param errorMessage - The error message to display if validation fails.
 * @returns A Yup string schema with the numbers validation applied.
 */
export const noNumbers = (errorMessage = "Numbers are not allowed") =>
  string().matches(/^[^0-9]*$/, errorMessage);

/**
 * Reusable function to create a Yup string validation
 * that disallows dangerous characters.
 *
 * @param errorMessage - The error message to display if validation fails.
 * @returns A Yup string schema with the dangerous characters validation applied.
 */
export const noDangerousChars = (
  errorMessage = "Special characters are not allowed",
) => string().matches(/^[^<>/{}[\]"'`]*$/, errorMessage);

/**
 * Reusable function to create a Yup validation
 * that allows only certain file types.
 *
 * @param allowedTypes - An array of allowed file types.
 * @param errorMessage - The error message to display if validation fails.
 * @returns A Yup mixed schema with the file type validation applied.
 */
export const allowedFileTypes = (
  allowedTypes: string[],
  errorMessage = "Invalid file type",
) =>
  mixed().test("fileType", errorMessage, (file) => {
    if (!file) return true; // Allow if no file
    const fileType = file.name.split(".").pop();
    return allowedTypes.includes(fileType);
  });

/**
 * Reusable function to create a Yup validation
 * that disallows spaces.
 *
 * @param errorMessage - The error message to display if validation fails.
 * @returns A Yup string schema with the spaces validation applied.
 */
export const withoutOnlySpaces = (errorMessage = "Spaces are not allowed") =>
  string().test("no-spaces", errorMessage, (value) => {
    if (!value) return true; // Allow empty strings if not required
    return !/^\s+$/.test(value);
  });

/**
 * Reusable function to create a Yup string validation
 * that trims whitespace from start and end, but allows spaces between words.
 *
 * @param errorMessage - The error message to display if validation fails.
 * @returns A Yup string schema with the whitespace validation applied.
 */
export const noLeadingTrailingSpaces = (
  errorMessage = "Input cannot start or end with spaces",
) =>
  string().test("no-leading-trailing-spaces", errorMessage, (value) => {
    if (!value) return true; // Allow empty strings if not required
    return value.trim() === value;
  });

export const sanitizedString = (errorMessage?: string) =>
  stringWithoutURL(errorMessage)
    .concat(noHTMLDom(errorMessage))
    .concat(noDangerousChars(errorMessage))
    .concat(noLeadingTrailingSpaces(errorMessage))
    .concat(withoutOnlySpaces(errorMessage));
