import { isEmpty } from "lodash";
import { Fragment, memo, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import EmptyStateIllustration from "assets/illustrations/settlements-empty-state.svg";
import { EmptyState } from "components/EmptyState/EmptyStateV2";
import { Table } from "components/Table/V2/TableV2";

import { Divider } from "@mui/material";
import { TableRecord } from "components/Table/TableRecord";
import Pagination from "components/ui/Pagination/PaginationV2";
import { useSideSheetContext } from "design_system/Sidesheet/SideSheetContext";
import type { TagColors } from "design_system/Tag/typeDefs";
import { CashbackDetails } from "features/Wallet/WalletV2/features/Cashback/_views/Dashboard/_components/CashbackDetails";
import {
  STATUS_COLOR_MAP,
  STATUS_ICON_MAP,
  STATUS_MAP,
  useCashbackTable,
} from "features/Wallet/WalletV2/features/Cashback/_views/Dashboard/_containers/Table/useCashbackTable";
import type { WalletTransaction } from "features/Wallet/WalletV2/typeDefs";
import { useScreenDimension } from "hooks/useScreenDimension";

import { TableRecordPlaceholder } from "components/Table/TableRecordPlaceholder";
import type { PaginationMeta } from "repositories/_base/pagination.types";
import { useTransactionsFor } from "repositories/finances/useTransactionsFor";

const PAGINATION_META_INITIAL: PaginationMeta = {
  totalCount: 0,
  totalPages: 0,
  page: 0,
  from: 0,
  to: 0,
  perPage: 0,
  nextPage: 0,
  prevPage: 0,
};

export const CashbackTable = memo(() => {
  const { identifier } = useParams();
  const { isMobile } = useScreenDimension();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    Number.parseInt(searchParams.get("page") || "1"),
  );

  const { openSideSheet } = useSideSheetContext();

  const [selectedTransaction, setSelectedTransaction] =
    useState<WalletTransaction | null>(null);

  const { data, isLoading } = useTransactionsFor(identifier, {
    page,
    perPage: 100,
  });

  const handleClick = (props: WalletTransaction) => {
    setSelectedTransaction(props);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      transactionId: props.id,
    });
    openSideSheet();
  };

  const handleSetPage = (page: number) => {
    setSearchParams({
      page: page.toString(),
    });
    setPage(page);
  };

  const { COLUMNS } = useCashbackTable({ onClick: handleClick });

  const readableData = useMemo(() => {
    if (data?.success) {
      return data.data;
    }

    return [];
  }, [data]);

  const readablePaginationMeta = useMemo(() => {
    if (data?.success) {
      return data.paginationMeta;
    }

    return PAGINATION_META_INITIAL;
  }, [data]);

  useEffect(() => {
    if (
      readableData &&
      !isEmpty(readableData) &&
      searchParams.get("transactionId")
    ) {
      const transaction = readableData?.find(
        (item) => item.id === searchParams.get("transactionId"),
      );

      if (!transaction) {
        searchParams.delete("transactionId");
        setSearchParams(searchParams);
        return;
      }

      setSelectedTransaction(transaction);
      openSideSheet();
    }
  }, [searchParams, readableData]);

  return (
    <>
      {!isMobile ? (
        <>
          <Table
            name="cashback-table"
            columns={COLUMNS}
            isLoading
            data={isLoading ? [] : readableData}
            paginationMeta={readablePaginationMeta}
            onSetPage={handleSetPage}
            tableClass="table"
            headerRowClass="bg-black-25"
            headerCellClass="w-auto"
            containerClass="px-16 md:px-40"
            rowProps={{
              selectable: false,
              selectedRows: [],
              setSelectedRows: () => {},
            }}
            emptyState={
              isLoading ? null : (
                <EmptyState
                  data-testid="empty-state-wrapper"
                  imgSrc={EmptyStateIllustration}
                  title="No cashback earned (yet)"
                  info="Spend on your cards and earn instant cashback on any foreign currency transaction"
                />
              )
            }
          />
        </>
      ) : (
        <>
          <div className="mt-24">
            {isLoading &&
              Array.from({ length: 10 }).map((_, index) => (
                <TableRecordPlaceholder key={index}>
                  <TableRecordPlaceholder.Avatar />
                  <TableRecordPlaceholder.Column1 />
                  <TableRecordPlaceholder.Column2 />
                </TableRecordPlaceholder>
              ))}
            {!isLoading &&
              !isEmpty(readableData) &&
              readableData.map((cashback) => {
                return (
                  <Fragment key={cashback.id}>
                    <TableRecord
                      onClick={() => {
                        handleClick(cashback);
                      }}
                    >
                      <TableRecord.Tag
                        tagProps={{
                          size: "xl",
                          iconOnly: true,
                          color: STATUS_COLOR_MAP[cashback.status] as TagColors,
                          label: STATUS_MAP[cashback.status],
                          Icon: STATUS_ICON_MAP[cashback.status],
                        }}
                      />

                      <TableRecord.Column1
                        label={cashback.activityName}
                        supportingText={cashback.date}
                      />
                      <TableRecord.Column2 label={cashback.amountFormatted} />
                    </TableRecord>
                    <Divider />
                  </Fragment>
                );
              })}

            {!isLoading && isEmpty(readableData) && (
              <EmptyState
                imgSrc={EmptyStateIllustration}
                title="No cashback earned (yet)"
                info="Spend on your cards and earn instant cashback on any foreign currency transaction"
              />
            )}
          </div>

          <Pagination
            className="mt-16 px-16"
            meta={readablePaginationMeta}
            itemName="cashback"
            onSetPage={setPage}
          />
        </>
      )}
      <CashbackDetails data={selectedTransaction} />
    </>
  );
});

CashbackTable.displayName = "CashbackTable";
