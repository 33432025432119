import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import LoaderBusiness from "components/Loader/LoaderBusiness";
import { ProgressBar } from "components/ProgressBar";
import { Card } from "design_system/Card";
import { VisaCardWithBackground } from "design_system/Icons";
import { CardsUpsellBanner } from "./components/CardsUpsellBanner";
import { PageHeader } from "./components/PageHeader";
import { ProgressBarTooltip } from "./components/ProgressBarTooltip";

import { useGetWalletsAndCards } from "./hooks/useGetWalletsAndCards";

import { colorsV2 } from "constants/colors";
import routes from "constants/routes";
import { CashbackAccountBox } from "features/Wallet/WalletV2/components/CashbackAccountBox";
import { useScreenDimension } from "hooks/useScreenDimension";
import { cn } from "utils/classNames";
import { currencyMappings } from "./constants/currencyMappings";
import type {
  CreditCardDetail,
  DebitCardDetail,
  WalletDetail,
  WalletV2Props,
} from "./typeDefs";

export const WalletV2 = ({ isFreelancer }: WalletV2Props) => {
  const navigate = useNavigate();
  const { isMobile } = useScreenDimension();

  const { getWalletsAndCards } = useGetWalletsAndCards();

  const walletsAndCards = getWalletsAndCards.data;

  // Freelancer will never have cards, business might or might not have cards
  const showCards =
    !isFreelancer &&
    !!walletsAndCards?.cards &&
    walletsAndCards?.cards?.length > 0;

  // If business is not freelancer and does not have cards, show upsell banner
  const showCardsUpsellBanner = !isFreelancer && !showCards;

  const handleSelectWallet = (wallet) => {
    navigate(routes.MANAGE.WALLET.SHOW(wallet.id));
  };

  const handleSelectCard = (card) => {
    if (card.type === "debit") {
      window.location.href = routes.MANAGE.CARDS.BALANCE();
    } else {
      navigate(routes.MANAGE.WALLET.CREDIT_CARD_DETAIL());
    }
  };

  useEffect(() => {
    localStorage.removeItem("wallet");
  }, []);

  if (!getWalletsAndCards.isError && getWalletsAndCards.isLoading) {
    return (
      <section>
        <LoaderBusiness />
      </section>
    );
  }

  return (
    <>
      <PageHeader isMobile={isMobile} />

      <section className="px-16 md:px-40">
        {/* ACCOUNTS SECTION */}
        <div className="space-y-24 py-16 md:py-40">
          {walletsAndCards?.wallets?.map((wallet: WalletDetail) => {
            const mapping = currencyMappings[wallet.currency];

            return (
              <Card
                key={wallet.id}
                cardClasses="md:w-320 min-h-16 justify-between md:min-h-166 flex flex-col gap-40 bg-surface-2 cursor-pointer md:max-w-320 hover:bg-surface-3 hover:border hover:border-border-1 p-16"
                onClick={() => handleSelectWallet(wallet)}
              >
                <mapping.Icon size="40" />
                <div className="space-y-4">
                  <h2 className="font-medium text-18 text-text-color-01">
                    {wallet.totalBalanceFormatted}
                  </h2>
                  <p className="body-medium text-text-color-02">
                    {mapping.label}
                  </p>
                </div>
              </Card>
            );
          })}
        </div>

        {/* CARDS SECTION */}

        {/* Merchant is not a freelancer */}
        {showCards || showCardsUpsellBanner ? (
          <>
            <div className="space-y-24 py-16 md:py-40">
              <h1 className="label-large md:header-medium">Cards</h1>

              {showCards ? (
                <div className="flex flex-col gap-24 md:flex-row">
                  {walletsAndCards.cards.map(
                    (card: DebitCardDetail | CreditCardDetail) => {
                      const heading =
                        card.type === "debit"
                          ? card.totalBalanceFormatted
                          : card.balanceFormatted;
                      const supportingText =
                        card.type === "debit"
                          ? "Debit - Available balance"
                          : "Credit - Available credit";

                      const creditConsumed =
                        card.type === "credit"
                          ? card.creditLimit - card.balance
                          : 0;
                      const progress =
                        card.type === "credit" && card.creditLimit > 0
                          ? (creditConsumed / card.creditLimit) * 100
                          : 0;

                      return (
                        <Card
                          key={card.id}
                          cardClasses={cn(
                            "flex min-h-158 cursor-pointer flex-col justify-between bg-surface-2 p-16 hover:border hover:border-border-1 hover:bg-surface-3 md:min-h-174 md:w-320 md:max-w-320",
                            {
                              "justify-between":
                                !isMobile && card.type === "debit",
                              "gap-32": isMobile || card.type === "credit",
                            },
                          )}
                          onClick={() => handleSelectCard(card)}
                        >
                          <VisaCardWithBackground
                            size="40"
                            variant={card.type}
                          />
                          <div className="space-y-4">
                            {card.type === "credit" && (
                              <ProgressBarTooltip
                                balance={card.balance}
                                creditLimit={card.creditLimit}
                              >
                                <ProgressBar
                                  progress={progress}
                                  backgroundColor={colorsV2.accent.blue[3]}
                                  progressColor={colorsV2.qualitative.blue[1]}
                                />
                              </ProgressBarTooltip>
                            )}
                            <h2 className="header-small text-text-color-01">
                              {heading}
                            </h2>
                            <p className="body-medium text-text-color-02">
                              {supportingText}
                            </p>
                          </div>
                        </Card>
                      );
                    },
                  )}
                </div>
              ) : (
                // They are not using cards feature
                <CardsUpsellBanner />
              )}
            </div>

            {/* CASHBACK SECTION */}

            {showCards && walletsAndCards.cashback_wallet ? (
              <div className="space-y-24 py-16 md:py-40">
                <h1 className="label-large md:header-medium">Rewards</h1>
                <CashbackAccountBox data={walletsAndCards.cashback_wallet} />
              </div>
            ) : null}
          </>
        ) : // They are using cards feature but do not have any cards created at the moment
        null}
      </section>
    </>
  );
};
