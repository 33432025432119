import { routes } from "constants/routes";
import { Card } from "design_system/Card";
import type { WalletDetail } from "features/Wallet/WalletV2/typeDefs";
import { withCdn } from "utils/withCdn";

export type CashbackAccountBoxProps = {
  data: WalletDetail;
};
export const CashbackAccountBox = ({ data }: CashbackAccountBoxProps) => {
  return (
    <a
      href={routes.MANAGE.WALLET.CASHBACK.SINGLE(data.id)}
      className="block md:inline-block"
      data-testid="cashback-account-box"
    >
      <Card cardClasses="md:w-320 flex min-h-1158 md:min-h-166 justify-between flex-col gap-40 bg-surface-2 cursor-pointer md:max-w-320 hover:bg-surface-3 hover:border hover:border-border-1 p-16">
        <figure className="h-40 w-40 overflow-hidden rounded-full">
          <img src={withCdn("assets/cashback_bag.png")} alt="" />
        </figure>
        <div className="space-y-4">
          <h2 className="font-medium text-18 text-text-color-01">
            {data.totalBalanceFormatted}
          </h2>
          <p className="text-14 text-text-color-02">Cashback</p>
        </div>
      </Card>
    </a>
  );
};
