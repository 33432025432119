import { memo } from "react";

import { useSearchParams } from "react-router-dom";
import { cn } from "utils/classNames";

// Design System
import { OpenInNew } from "design_system/Icons";
import { LabeledGrid } from "design_system/LabeledGrid";
import { useSideSheetContext } from "design_system/Sidesheet/SideSheetContext";
import SideSheet from "design_system/Sidesheet/Sidesheet";
import { Skeleton } from "design_system/Skeleton";
import { Tag } from "design_system/Tag";
import type { TagColors } from "design_system/Tag/typeDefs";
import { Tooltip } from "design_system/Tooltip";

import { Divider } from "components/Divider";

import {
  STATUS_COLOR_MAP,
  STATUS_ICON_MAP,
  STATUS_MAP,
} from "features/Wallet/WalletV2/features/Cashback/_views/Dashboard/_containers/Table/useCashbackTable";
import type { WalletTransaction } from "features/Wallet/WalletV2/typeDefs";

const ActivityDetails = memo(
  ({ data }: { data: WalletTransaction | undefined }) => (
    <LabeledGrid.SectionTitle title="Activity details">
      {({ ItemRow }) => (
        <>
          <ItemRow label="Type">
            {data ? (
              <span className="capitalize">{data.type}</span>
            ) : (
              <Skeleton className="h-24 w-104" />
            )}
          </ItemRow>
          <ItemRow label="Date">
            {data ? data.date : <Skeleton className="h-24 w-136" />}
          </ItemRow>
          <ItemRow label="Amount">
            {data ? data.amountFormatted : <Skeleton className="h-24 w-136" />}
          </ItemRow>
        </>
      )}
    </LabeledGrid.SectionTitle>
  ),
);

ActivityDetails.displayName = "ActivityDetails";

const TransactionDetails = memo(({ data }: { data: WalletTransaction }) => (
  <LabeledGrid.SectionTitle title="Transaction details">
    {({ ItemRow }) => (
      <ItemRow label="Transaction ID">
        <div className="flex items-center gap-4">
          {data.reference.id}
          <a href={data.reference.link}>
            <Tooltip
              Icon={<OpenInNew className="h-18 w-18" />}
              content="View transaction"
              contentPosition="bottom"
            />
          </a>
        </div>
      </ItemRow>
    )}
  </LabeledGrid.SectionTitle>
));

TransactionDetails.displayName = "TransactionDetails";

type CashbackDetailsProps = {
  data: WalletTransaction;
  className?: string;
};

export function CashbackDetails({ data, className }: CashbackDetailsProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { closeSideSheet } = useSideSheetContext();

  const handleClose = () => {
    searchParams.delete("transactionId");
    setSearchParams(searchParams);
    closeSideSheet();
  };

  return (
    <SideSheet
      onClose={handleClose}
      data-test-id="cashback-details"
      style={{ zIndex: 1002 }}
      className={cn("cashback-details relative", className)}
    >
      <SideSheet.Navbar className="px-16 py-12 md:px-32 md:py-24">
        <div className="flex items-center justify-between">
          {data?.status && (
            <Tag
              color={STATUS_COLOR_MAP[data.status] as TagColors}
              label={STATUS_MAP[data.status]}
              Icon={STATUS_ICON_MAP[data.status]}
            />
          )}
          <SideSheet.Close className="ml-auto" onClose={handleClose} />
        </div>
      </SideSheet.Navbar>

      <SideSheet.Header>
        <div className={cn("flex flex-col gap-4", { "gap-2": !data })}>
          <SideSheet.Description>{data?.activityName}</SideSheet.Description>
          <SideSheet.Display>{data?.amountFormatted}</SideSheet.Display>
        </div>
      </SideSheet.Header>

      <Divider />

      <div className="p-16 md:p-32">
        <ActivityDetails data={data} />
        {data?.reference && <TransactionDetails data={data} />}
      </div>
    </SideSheet>
  );
}
