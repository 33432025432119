import { MasterHeader } from "design_system/MasterHeader";
import { TransferFundsButton } from "features/Wallet/WalletV2/components/TransferFundsButton";
import { TransferFundsSuccess } from "features/Wallet/WalletV2/components/TransferFundsSuccess";

export const PageHeader = ({ isMobile }: { isMobile: boolean }) => {
  return (
    <MasterHeader>
      <MasterHeader.Title>Accounts</MasterHeader.Title>
      <MasterHeader.Actions>
        <TransferFundsSuccess />
        <TransferFundsButton />
      </MasterHeader.Actions>
    </MasterHeader>
  );
};
