import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Snackbar, snack } from "design_system/Snackbar";

export function TransferFundsSuccess() {
  const [searchParams, setSearchParams] = useSearchParams();

  const transferSuccess = searchParams.get("transferSuccess");
  const message = searchParams.get("message");

  useEffect(() => {
    if (!transferSuccess || !message) return;

    snack({
      title: message,
      leadingElement: "icon",
      variant: "success",
    });

    searchParams.delete("transferSuccess");
    searchParams.delete("message");

    const timeout = setTimeout(() => {
      setSearchParams(searchParams);
    }, 500);

    return () => clearTimeout(timeout);
  }, [transferSuccess, message]);

  return <Snackbar />;
}
