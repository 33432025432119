import type { HTMLAttributes, ReactNode } from "react";
import { cn } from "utils/classNames";

export type LabelProps = HTMLAttributes<HTMLLabelElement> & {
  children: ReactNode;
  htmlFor?: string;
};
export const Label = ({
  children,
  className,
  htmlFor,
  ...props
}: LabelProps) => {
  return (
    <label
      className={cn("label-large", className)}
      htmlFor={htmlFor}
      {...props}
    >
      {children}
    </label>
  );
};
