import numeral from "numeral";

/**
 * Converts formatted currency strings into plain numbers. This function uses Numeral.js
 * to parse numbers in different localized formats.
 *
 * @param {string} currencyStr - The currency string to convert, e.g., "EUR 1.234,56".
 * @returns {number | null} - The numeric value of the currency string, or null if parsing fails.
 */
export const convertCurrencyToNumber = (currencyStr: string) => {
  if (typeof currencyStr !== "string") {
    console.error("Invalid input: currencyStr must be a string.");
    return null;
  }

  // Remove the currency code and any alphabetic characters
  const numberPart = currencyStr.replace(/[^0-9,.]/g, "").trim();

  // Use numeral to parse the number
  const number = numeral(numberPart).value();

  // Check if the number is valid
  if (number === null) {
    console.error("Failed to parse the number:", currencyStr);
    return null;
  }

  return number;
};
// todo: Revisit the function for scenarios where the currency string is not in the expected format.
/* it("should convert a valid formatted currency string to a number", () => {
    const result = convertCurrencyToNumber("EUR 1.234,56");
    expect(result).toBe(1234.56);
  }); */
