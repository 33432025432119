export const WalletType = {
  DEBIT_ACCOUNT: "debit",
  CREDIT_CARD_WALLET: "credit",
  CASHBACK_WALLET: "cashback_wallet",
  MAIN_WALLET: "main_wallet",
} as const;

export type WalletType = (typeof WalletType)[keyof typeof WalletType];

export type Wallet = {
  id: string;
  currency: string;
  isoCode: string;
  type: WalletType;
  autoSettlementEnabled: boolean;
  totalBalance: number;
  totalBalanceFormatted: string;
  availableBalance: number;
  availableBalanceFormatted: string;
  incomingBalance: number;
  incomingBalanceFormatted: string;
  incomingAmountByDates: {
    date: string;
    amount: number;
    amountFormatted: string;
  }[];
};
