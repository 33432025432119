import type { ComplianceType } from "types/ComplianceType";
import type { Role } from "types/Role";
import type { SignedUpFor } from "types/SignedUpFor";

export const ComplianceDocumentTypes = {
  other: "other",
  trade_license: "trade_license",
  emirates_id: "emirates_id",
  proof_of_address: "proof_of_address",
  articles_of_association: "articles_of_association",
  memorandum_of_association: "memorandum_of_association",
  public_register: "public_register",
  passport: "passport",
  visa: "visa",
  freelance_permit: "freelance_permit",
  proof_of_service: "proof_of_service",
  latest_bank_statement: "latest_bank_statement",
  sample_invoice: "sample_invoice",
} as const;

export type ComplianceDocumentType =
  (typeof ComplianceDocumentTypes)[keyof typeof ComplianceDocumentTypes];

export const MerchantStatuses = {
  verified: "verified",
  on_hold: "on_hold",
  suspended: "suspended",
  pending_verification: "pending_verification",
  not_complete: "not_complete",
  failed_verification: "failed_verification",
} as const;

export type MerchantStatus =
  (typeof MerchantStatuses)[keyof typeof MerchantStatuses];

export type ComplianceDocument = {
  documentType: ComplianceDocumentType;
  expired: boolean;
  expiredAt: string;
  id: number;
};

export type CurrentMerchant = {
  address: string | null;

  // @deprecated - Soon to be removed
  cardKybStatus: string;

  // @deprecated - Soon to be removed
  kybCurrentStep: string;

  cardPlatform: "nc" | "mcs";
  city: string | null;
  complianceType: ComplianceType;
  country: string | null;
  documentsToUpdate: ComplianceDocument[] | null;
  eligibleForExpeditedSettlements: boolean;
  expectSpendOnCardsMonthly: string | null;
  identifier: string;
  isMerchantVerified: boolean;
  last30DaysGmv: number;
  name: string;
  notCompleteMessages: string[] | null;
  cardAgreeTermsAgreementAt: string | null;
  planSelected: boolean;
  premiumAccess: boolean;
  signedUpFor: SignedUpFor;
  status: MerchantStatus;
  trialAccess: boolean | null;
  trialRemainingDays: number;
};

export type MerchantFeatureToggles = {
  cardsFeatureEnabled: boolean;
  expensesAccountingIntegrationEnabled: boolean;
  expensesFeatureEnabled: boolean;
  walletFeatureEnabled: boolean;
};

export type CurrentUser = {
  email: string;
  firstName: string;
  identifier: string;
  lastName: string;
  middleName: string | null;
  nameInitials: string;
  roles: [{ name: string; id: string | number }];
  permissions: Role;
  phoneNumber: string;
  phoneVerified: boolean;
};

export type MerchantInfo = {
  currentMerchant: CurrentMerchant;
  currentUser: CurrentUser;
  featureToggles: MerchantFeatureToggles;
  platform: string | null;
};

export type MerchantState = {
  currentMerchant?: CurrentMerchant;
  currentUser?: CurrentUser;
  featureToggles?: MerchantFeatureToggles;
};
