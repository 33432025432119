import {
  CircularProgress as MuiCircularProgress,
  type CircularProgressProps as MuiCircularProgressProps,
  circularProgressClasses,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { type JSXElementConstructor, useEffect, useState } from "react";

import { colorsV2 } from "constants/colors";
import { Done } from "design_system/Icons";
import type { SvgIconProps } from "types/Icon";
import { cn } from "utils/classNames";
import { hexToRgb } from "utils/hexToRgbUtils";

export interface CircularProgressProps extends MuiCircularProgressProps {
  complete?: boolean;
  Icon?: JSXElementConstructor<SvgIconProps>;
  iconColor?: string;
  iconClassName?: string;
  progressSize?: "sm" | "md";
  className?: string;
}

export const CircularProgress = ({
  complete,
  Icon,
  iconColor,
  iconClassName,
  progressSize = "sm",
  className,
  ...props
}: CircularProgressProps) => {
  const [showComplete, setShowComplete] = useState(false);

  const sharedProps: MuiCircularProgressProps = {
    variant: "determinate",
    thickness: 2.8,
    size: progressSize === "sm" ? "20px" : "32px",
  };

  useEffect(() => {
    if (complete) {
      const timer = setTimeout(() => {
        setShowComplete(true);
      }, 300);
      return () => clearTimeout(timer);
    }
    setShowComplete(false);
  }, [complete]);

  return (
    <div
      className={cn("relative", className)}
      style={{
        width: progressSize === "sm" ? "20px" : "32px",
        height: progressSize === "sm" ? "20px" : "32px",
      }}
      data-testid="circular-progress"
    >
      <AnimatePresence>
        {showComplete && (
          <motion.div
            key="complete"
            data-testid="complete"
            initial={{ scale: 0, rotate: 0 }}
            animate={{ scale: 1, rotate: 360 }}
            exit={{ scale: 0, rotate: 0 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
              duration: 0.5,
            }}
            className="absolute inset-0 flex h-32 w-32 items-center justify-center rounded-full bg-qualitative-green-01 p-8"
          >
            <Done size="24" fill={colorsV2.text.onprimary} />
          </motion.div>
        )}

        {!showComplete && (
          <motion.div
            key="progress"
            data-testid="progress"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="absolute inset-0 flex items-center justify-center rounded-full"
            style={{ backgroundColor: "#ffffff" }}
          >
            <MuiCircularProgress
              sx={{
                color:
                  props.value === 0
                    ? colorsV2.surface[3]
                    : `rgba(${hexToRgb(colorsV2.primary[1])}, 0.15)`,
              }}
              value={100}
              aria-valuenow={props.value} // Add this
              {...sharedProps}
            />
            <MuiCircularProgress
              sx={{
                color: colorsV2.primary[1],
                position: "absolute",
                left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: "round",
                },
              }}
              value={props.value}
              {...sharedProps}
            />
            {Icon && (
              <Icon
                size="14"
                fill={iconColor}
                className={cn(iconClassName)}
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "50%",
                  transform: "translate(50%, -50%)",
                }}
                data-testid="icon"
              />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
