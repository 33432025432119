import { useNavigate, useParams } from "react-router-dom";

import { routes } from "constants/routes";

import { MasterHeader } from "design_system/MasterHeader";
import { Skeleton } from "design_system/Skeleton";
import { TransferFundsButton } from "features/Wallet/WalletV2/components/TransferFundsButton";
import { financesHooks } from "repositories/finances";
import { withCdn } from "utils/withCdn";

export const Header = () => {
  const navigate = useNavigate();
  const { identifier } = useParams();

  const { data, isLoading } = financesHooks.useByIdentifier(identifier);

  const handleBackButtonClick = () => {
    navigate(routes.MANAGE.WALLET.INDEX());
  };

  return (
    <MasterHeader onBack={handleBackButtonClick}>
      <MasterHeader.Image>
        <figure className="h-56 w-56 overflow-hidden rounded-full bg-red-25">
          <img src={withCdn("assets/cashback_bag.png")} alt="" />
        </figure>
      </MasterHeader.Image>

      <MasterHeader.Actions>
        <TransferFundsButton fromAccount={data?.data?.type} />
      </MasterHeader.Actions>

      <MasterHeader.Label>Cashback</MasterHeader.Label>

      <MasterHeader.Title>
        {isLoading ? (
          <Skeleton className="h-36 w-112" />
        ) : (
          data?.data?.totalBalanceFormatted
        )}
      </MasterHeader.Title>
    </MasterHeader>
  );
};
