import EmptyStateIllustration from "assets/illustrations/payments-empty-state.svg";
import { EmptyState } from "components/EmptyState/EmptyStateV2";

export const TableEmptyState = () => {
  return (
    <EmptyState
      data-testid="empty-state-wrapper"
      imgSrc={EmptyStateIllustration}
      title="No categories to show at the moment"
    />
  );
};
