import InputAdornment from "@mui/material/InputAdornment";
import {
  NumberFormatBase,
  NumericFormat,
  type NumericFormatProps,
} from "react-number-format";

import { Percent } from "design_system/Icons";
import { type ITextInputProps, TextInput } from "../TextInput/TextInput";

import { colorsV2 } from "constants/colors";
import type { CurrencyCode } from "constants/currency";
import { forwardRef } from "react";

export type NumberInputProps = {
  currency?: CurrencyCode;
  formatType: "uaeCurrencyOnly" | "dynamicCurrency" | "percentage";
  removePrefix?: boolean;
} & ITextInputProps &
  NumericFormatProps;

export const NumberInput = ({
  currency,
  formatType,
  removePrefix,
  ...props
}: NumberInputProps) => {
  const uaeCurrencyFormat = (numStr) => {
    if (numStr === "") return "AED ";
    return new Intl.NumberFormat("en-AE", {
      style: "currency",
      currency: "AED",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(numStr);
  };

  if (formatType === "uaeCurrencyOnly") {
    return (
      <NumberFormatBase
        {...props}
        customInput={TextInput}
        format={uaeCurrencyFormat}
      />
    );
  }

  if (formatType === "dynamicCurrency") {
    return (
      <NumericFormat
        {...props}
        customInput={TextInput}
        thousandSeparator=","
        decimalScale={2}
        fixedDecimalScale
        prefix={removePrefix ? "" : `${currency} `}
      />
    );
  }

  return (
    <NumericFormat
      {...props}
      customInput={TextInput}
      thousandSeparator=","
      decimalScale={2}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Percent size="18" fill={colorsV2.text[2]} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export const ForwardedNumberInput = forwardRef<
  HTMLInputElement,
  NumberInputProps
>((props, ref) => <NumberInput {...props} inputRef={ref} />);

ForwardedNumberInput.displayName = "ForwardedNumberInput";
