import * as RadixPopover from "@radix-ui/react-popover";
import * as RadixTooltip from "@radix-ui/react-tooltip";

export type TTooltip = {
  contentPosition?: "top" | "right" | "bottom" | "left" | undefined;
  contentWidth?: string;
  description: string;
  iconName?: string;
  iconSize?: string;
  iconColor?: string;
};

const Tooltip = ({
  contentPosition = "bottom",
  contentWidth = "24.4rem",
  description,
  iconName = "info",
  iconSize = "18",
  iconColor = "black-200",
}: TTooltip) => {
  const sharedTriggerClasses = `text-${iconColor} hover:text-black-500 transition-all ease-linear text-${iconSize} material-icons-round`;

  const tooltipContentProps = {
    sideOffset: 10,
    className: "tooltip",
    style: { width: contentWidth },
    side: contentPosition,
  };

  return (
    <>
      <div className="flex lg:hidden">
        <RadixPopover.Root aria-role="tooltip">
          <RadixPopover.Trigger
            className={sharedTriggerClasses}
            style={{
              backgroundColor: "unset",
              border: "unset",
            }}
            type="button"
          >
            {iconName}
          </RadixPopover.Trigger>

          <RadixPopover.Content {...tooltipContentProps}>
            {description}
            <RadixPopover.Arrow fill="#26303e" />
          </RadixPopover.Content>
        </RadixPopover.Root>
      </div>

      <div className="hidden lg:flex lg:items-center">
        <RadixTooltip.Root delayDuration={100}>
          <RadixTooltip.Trigger className={sharedTriggerClasses} type="button">
            {iconName}
          </RadixTooltip.Trigger>

          <RadixTooltip.Content {...tooltipContentProps}>
            {description}
            <RadixTooltip.Arrow fill="#26303e" />
          </RadixTooltip.Content>
        </RadixTooltip.Root>
      </div>
    </>
  );
};

export default Tooltip;
