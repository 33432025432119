import EmptyStateIllustration from "assets/illustrations/payments-empty-state.svg";
import { EmptyState } from "components/EmptyState/EmptyStateV2";

export const TableEmptyState = () => {
  return (
    <EmptyState
      data-testid="empty-state-wrapper"
      imgSrc={EmptyStateIllustration}
      title="No activity on this account yet"
      info="Once you add funds or receive payments, it will show up here. So much potential! 🤩"
    />
  );
};
