import { ReactQueryProvider } from "components/Providers";
import { BrandingContainer } from "./index";

export const BrandingRoot = () => {
  return (
    <ReactQueryProvider>
      <BrandingContainer />
    </ReactQueryProvider>
  );
};
