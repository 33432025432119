import { yupResolver } from "@hookform/resolvers/yup";
import { type ChangeEvent, useCallback, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { type InferType, object, string } from "yup";

import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { Button } from "design_system/Button";
import { Dropdown } from "design_system/Dropdown/DropdownV2";
import { FormItem } from "design_system/Form";
import {
  RadioGroup,
  RadioGroupItem,
  RadioGroupLabel,
} from "design_system/Form/RadioGroup";
import { ForwardedNumberInput } from "design_system/Inputs/NumberInput/NumberInput";
import { Label } from "design_system/Label";
import { snack } from "design_system/Snackbar";

import { VisaCardWithBackground } from "design_system/Icons";
import { CashbackIcon } from "design_system/Icons/Cashback";

import { FullPageLayout } from "features/Cards/features/CreateCard/core/components/FullPageLayout/FullPageLayout";
import {
  AccountItem,
  type AccountItemProps,
} from "features/Wallet/WalletV2/features/TransferFunds/Form/AccountItem";

import { routes } from "constants/routes";
import { currencyMappings } from "features/Wallet/WalletV2/constants/currencyMappings";
import { cn } from "utils/classNames";
import { convertCurrencyToNumber } from "utils/numberUtils";

import { type Wallet, WalletType } from "types/Wallet";

import { financesHooks } from "repositories/finances";

export const transferFundsFormSchema = object({
  from_account: string()
    .min(1, "From is required")
    .required("From is required"),
  to_account: string()
    .required("To is required")
    .oneOf(
      Object.values(WalletType).map((type) => type.toString()),
      "Invalid account type selected",
    ),
  amount: string()
    .min(1, "Amount is required")
    .required("Amount is required")
    .test(
      "balance-check",
      "Amount exceeds available balance",
      function (value, context) {
        const { from_account, option } = context.parent;

        // Skip validation if "all" is selected

        if (option === "all") return true;

        // Get the selected account from context
        const selectedAccount = (
          context.options.context?.fromAccounts as Wallet[]
        ).find((account) => account.type === from_account);

        if (!selectedAccount || !value) return false;

        const numericAmount = convertCurrencyToNumber(value);
        const accountBalance = convertCurrencyToNumber(
          selectedAccount.totalBalanceFormatted,
        );

        return numericAmount <= accountBalance;
      },
    )
    .test("minimal-value", "Minimum transfer amount is 1", (value) => {
      if (!value) return false;
      return convertCurrencyToNumber(value) >= 1;
    }),
  option: string().oneOf(["all", "partial"]),
});

const selectPlaceholder = "Select account";

type TransferFundsFormValues = InferType<typeof transferFundsFormSchema>;

const WALLET_TYPE_TO_COMPONENT_MAP: Record<
  WalletType,
  (data: Wallet) => AccountItemProps
> = {
  [WalletType.CASHBACK_WALLET]: (data: Wallet) => ({
    icon: <CashbackIcon className="h-24 w-24" />,
    label: "Cashback balance",
    supportingText: data.totalBalanceFormatted,
  }),
  [WalletType.CREDIT_CARD_WALLET]: (data: Wallet) => ({
    icon: <VisaCardWithBackground variant={"debit"} size="24px" />,
    label: "Debit card balance",
    supportingText: data.totalBalanceFormatted,
  }),
  [WalletType.DEBIT_ACCOUNT]: (data: Wallet) => ({
    icon: <VisaCardWithBackground variant={"debit"} size="24px" />,
    label: "Debit card balance",
    supportingText: data.totalBalanceFormatted,
  }),
  [WalletType.MAIN_WALLET]: (data: Wallet) => {
    const mapping = currencyMappings[data.currency];

    return {
      icon: <mapping.Icon size="24px" />,
      label: `${mapping.label} account`,
      supportingText: data.totalBalanceFormatted,
    };
  },
};

// Helper functions
function findAccountByType(
  accounts: Wallet[] | undefined,
  type: string,
): Wallet | undefined {
  return accounts?.find((account) => account.type === type);
}

function getAccountItemProps(account: Wallet): AccountItemProps {
  return WALLET_TYPE_TO_COMPONENT_MAP[account.type](account);
}

export function TransferFundsForm() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { data, isLoading } = financesHooks.useTransferAccounts();

  const { mutateAsync: transferFunds } = financesHooks.useTransferFunds();

  const onSuccessUrl = searchParams.get("onSuccessUrl");
  const preSelectedFromAccount = searchParams.get("fromAccount");

  const form = useForm<TransferFundsFormValues>({
    resolver: yupResolver(transferFundsFormSchema),
    defaultValues: {
      option: "all",
      from_account: preSelectedFromAccount ?? "",
      to_account: null,
      amount: "",
    },
    mode: "all",
    reValidateMode: "onChange",
    context: {
      fromAccounts: data?.data?.from_accounts,
    },
  });

  const preFillAmount = useCallback(() => {
    if (preSelectedFromAccount) {
      const account = data?.data?.from_accounts.find(
        (account) => account.type === preSelectedFromAccount,
      );

      form.setValue("amount", account?.totalBalanceFormatted);
    }
  }, [preSelectedFromAccount, data?.data?.from_accounts]);

  useEffect(() => {
    preFillAmount();
  }, [preSelectedFromAccount, preFillAmount]);

  const onSubmit = async (data: TransferFundsFormValues) => {
    const response = await transferFunds({
      from_account: data.from_account,
      to_account: data.to_account,
      amount: convertCurrencyToNumber(data.amount),
    });

    if (!response.success && "error" in response) {
      snack({
        title: response.error.message,
        leadingElement: "icon",
        variant: "critical",
      });
    }

    if (response.success) {
      const fromAccount =
        WALLET_TYPE_TO_COMPONENT_MAP[selectedFromAccount?.type](
          selectedFromAccount,
        );

      const toAccount =
        WALLET_TYPE_TO_COMPONENT_MAP[selectedToAccount?.type](
          selectedToAccount,
        );

      navigate({
        pathname: onSuccessUrl ?? routes.MANAGE.WALLET.INDEX(),
        search: createSearchParams({
          transferSuccess: "true",
          message: `${form.watch("amount")} transferred from ${fromAccount.label} to ${toAccount.label}`,
        }).toString(),
      });
    }
  };

  const selectedFromAccount = useMemo(() => {
    return findAccountByType(
      data?.data?.from_accounts,
      form.watch("from_account"),
    );
  }, [data, form.watch("from_account")]);

  const selectedToAccount = useMemo(() => {
    return findAccountByType(data?.data?.to_accounts, form.watch("to_account"));
  }, [data, form.watch("to_account")]);

  const toAccounts = useMemo(() => {
    return data?.data?.to_accounts.filter(
      (account) => account.type !== selectedFromAccount?.type,
    );
  }, [data, selectedFromAccount]);

  const isCustomAmount = useMemo(() => {
    return form.watch("option") === "partial";
  }, [form.watch("option")]);

  const handleSelectFromAccount = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    const selectedAccount = findAccountByType(data?.data?.from_accounts, value);

    if (selectedAccount) {
      form.setValue("from_account", value);
      form.setValue("amount", selectedAccount.totalBalanceFormatted);
      form.setValue("option", "all");
      form.setValue("to_account", "");
    }
  };

  const submitDisabled = useMemo(() => {
    return [
      !form.formState.isValid,
      !form.formState.isDirty,
      !selectedFromAccount,
      !selectedToAccount,
    ].some(Boolean);
  }, [form.formState, selectedFromAccount, selectedToAccount]);

  return (
    !isLoading &&
    data && (
      <>
        <form
          id="transfer-funds-form"
          onSubmit={form.handleSubmit(onSubmit)}
          className="mx-auto mt-40 w-full max-w-410 px-16 md:px-0"
        >
          <FormItem>
            <Label>From account</Label>
            <Controller
              name="from_account"
              control={form.control}
              render={(props) => (
                <Dropdown
                  type="select"
                  selectProps={{
                    ...props,
                    value: props.value || selectPlaceholder,
                    placeholder: selectPlaceholder,
                    onChange: (e) => {
                      props.onChange(e);

                      handleSelectFromAccount(e);
                    },
                    renderValue: (value) => {
                      return value !== selectPlaceholder ? (
                        <div className="py-8">
                          <AccountItem
                            {...getAccountItemProps(selectedFromAccount)}
                          />
                        </div>
                      ) : (
                        selectPlaceholder
                      );
                    },
                  }}
                >
                  {data.data?.from_accounts.map((account) => {
                    return (
                      <Dropdown.MenuItem
                        key={account.type}
                        value={account.type}
                      >
                        <AccountItem {...getAccountItemProps(account)} />
                      </Dropdown.MenuItem>
                    );
                  })}
                </Dropdown>
              )}
            />
          </FormItem>
          <FormItem className="mt-48">
            <Label>How much would you like to transfer?</Label>
            <Controller
              name="option"
              control={form.control}
              defaultValue={form.watch("option") ?? "all"}
              render={(props) => (
                <RadioGroup
                  aria-label="Transfer funds option"
                  onValueChange={(value) => {
                    props.onChange(value);

                    if (value === "all" && selectedFromAccount) {
                      form.setValue(
                        "amount",
                        selectedFromAccount.totalBalanceFormatted,
                      );
                    } else if (value === "partial") {
                      form.setValue("amount", "");
                    }
                  }}
                  defaultValue={props.value}
                  className={cn("space-y-12", {
                    "pointer-events-none opacity-70":
                      !form.watch("from_account"),
                  })}
                >
                  <div className="flex items-center space-x-8">
                    <RadioGroupItem
                      value="all"
                      id="transfer-funds-option-all"
                    />
                    <RadioGroupLabel
                      htmlFor="transfer-funds-option-all"
                      className="cursor-pointer"
                    >
                      Full balance
                      {selectedFromAccount?.totalBalanceFormatted && (
                        <span>
                          - {selectedFromAccount?.totalBalanceFormatted}
                        </span>
                      )}
                    </RadioGroupLabel>
                  </div>

                  <div className="space-y-48">
                    <div className="flex items-center space-x-8">
                      <RadioGroupItem
                        value="partial"
                        id="transfer-funds-option-partial"
                      />
                      <RadioGroupLabel
                        htmlFor="transfer-funds-option-partial"
                        className="cursor-pointer"
                      >
                        Partial amount
                      </RadioGroupLabel>
                    </div>
                  </div>
                </RadioGroup>
              )}
            />
            {isCustomAmount ? (
              <Controller
                name="amount"
                control={form.control}
                render={(props) => (
                  <ForwardedNumberInput
                    {...props}
                    formatType="uaeCurrencyOnly"
                    className="max-w-200"
                    autoFocus={form.watch("option") === "partial"}
                    error={!!form.formState.errors.amount}
                    helperText={form.formState.errors.amount?.message}
                  />
                )}
              />
            ) : (
              <Controller
                name="amount"
                control={form.control}
                render={(props) => <input type="hidden" {...props} />}
                error={!!form.formState.errors.amount}
                helperText={form.formState.errors.amount?.message}
              />
            )}
          </FormItem>

          <FormItem className="mt-48">
            <Label>To account</Label>
            <Controller
              name="to_account"
              control={form.control}
              defaultValue=""
              render={(props) => (
                <Dropdown
                  type="select"
                  selectProps={{
                    ...props,
                    value: props.value || selectPlaceholder,
                    placeholder: selectPlaceholder,
                    renderValue: (value) => {
                      return value !== selectPlaceholder ? (
                        <div className="py-8">
                          <AccountItem
                            {...getAccountItemProps(selectedToAccount)}
                          />
                        </div>
                      ) : (
                        selectPlaceholder
                      );
                    },
                  }}
                >
                  {toAccounts.map((account) => {
                    return (
                      <Dropdown.MenuItem
                        key={account.type}
                        value={account.type}
                      >
                        <AccountItem {...getAccountItemProps(account)} />
                      </Dropdown.MenuItem>
                    );
                  })}
                </Dropdown>
              )}
            />
          </FormItem>
        </form>
        <FullPageLayout.Footer className="mt-auto border-surface-3 border-t px-16">
          <Button
            label="Transfer funds"
            disabled={submitDisabled}
            type="submit"
            form="transfer-funds-form"
            variant="filled"
            classes="w-full md:w-auto"
            color="primary"
            size="lg"
          />
        </FullPageLayout.Footer>
      </>
    )
  );
}
