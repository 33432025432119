import type React from "react";
import { cn } from "utils/classNames";

type TableRecordPlaceholderProps = {
  variant?: "noAvatar";
  className?: string;
  children: React.ReactNode;
};

type ColumnProps = {
  className?: string;
};

export const TableRecordPlaceholder: React.FC<TableRecordPlaceholderProps> & {
  Avatar: React.FC;
  Column1: React.FC<ColumnProps>;
  Column2: React.FC<ColumnProps>;
  Actions: React.FC;
} = ({ variant, className, children }: TableRecordPlaceholderProps) => {
  return (
    <div
      className={cn(
        "grid w-full animate-pulse gap-x-12 p-16",
        {
          "grid-cols-table-record": !variant,
          "grid-cols-table-record-no-avatar": variant === "noAvatar",
        },
        className,
      )}
    >
      {children}
    </div>
  );
};

TableRecordPlaceholder.Avatar = () => (
  <div className="h-40 w-40 rounded-full bg-surface-3" />
);

TableRecordPlaceholder.Column1 = ({ className }: ColumnProps) => (
  <div className={cn("flex flex-col gap-8", className)}>
    <div className="h-20 w-3/4 rounded-6 bg-surface-3" />
    <div className="h-16 w-1/2 rounded-6 bg-surface-3" />
  </div>
);

TableRecordPlaceholder.Column2 = ({ className }: ColumnProps) => (
  <div className={cn("flex flex-col items-end gap-8", className)}>
    <div className="h-20 w-24 rounded-6 bg-surface-3" />
    <div className="h-16 w-32 rounded-6 bg-surface-3" />
  </div>
);

TableRecordPlaceholder.Actions = () => (
  <div className="flex justify-end">
    <div className="h-24 w-32 rounded-6 bg-surface-3" />
  </div>
);
