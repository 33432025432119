import type { MerchantInfoResponse } from "repositories/merchant/_types/MerchantInfoResponse";
import { Repository } from "../_base/repository";

class MerchantRepository extends Repository {
  constructor() {
    super({
      gateway: "/merchants", // This is the endpoint. Added to baseURL
    });
  }

  public async getInfo() {
    return this._http
      .get<MerchantInfoResponse>("/info") // This one returns RAW AxiosResponse
      .then(this.handleResponseWithoutData) // This one produces { success: true, data: { ... } }
      .catch(this.handleError); // This one produces { success: false, error: { ... } }
  }
}

export const merchantRepository = new MerchantRepository();
