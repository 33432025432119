import { withCdn } from "utils/withCdn";

export type CashbackIconProps = {
  className?: string;
};

export const CashbackIcon = ({ className }: CashbackIconProps) => {
  return (
    <img
      src={withCdn("assets/cashback_bag.png")}
      alt="Cashback"
      className={className}
    />
  );
};
