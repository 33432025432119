import type { SettlementOptions } from "components/ChoosePlan/reducers/choosePlanReducer";

import { Repository } from "repositories/_base/repository";
import type { ApiResponse } from "repositories/_base/repository.types";

class PlansRepository extends Repository {
  constructor() {
    super({
      gateway: "/plans",
    });
  }

  public async getPlans() {
    return this._http
      .get<
        // TODO: Frank - to move to separate type when we migrate type separation
        ApiResponse<{
          activePlan: { name: string; id: string };
          plans: { name: string; id: string }[];
          selectedPlan: string | null;
          settlementOption: SettlementOptions | null;
          eligibleForExpeditedSettlements: boolean;
        }>
      >("/")
      .then(this.handleResponse)
      .catch(this.handleError);
  }
}

export const plansRepository = new PlansRepository();
