import type { UseFieldArrayMethods } from "react-hook-form";

import type { TCardType } from "./CreateCardForm.types";

export type SpendLimitControl = UseFieldArrayMethods<Record<string, any>, "id">;

export interface NewCardDetails {
  cardHolder: {
    identifier: string;
    fullname: string;
  };
  cardHolderName: string;
  cardLast4: string;
  cardName: string;
  cardType: "virtual" | "physical";
  createdAt: string;
  expiryDate: string | null;
  fulfillmentStatsu: "created";
  identifier: string;
  spendLimitsReached: any[];
  status: string;
  statusFormatted: string;
  totalSpend: number;
}

/**--------------------------------------------------------------------------------------
 * Component Types
 *-------------------------------------------------------------------------------------- */

export interface CreateCardContainerProps {
  isMobile: boolean;
  selectedCardType: TCardType;
  spendLimitControl: SpendLimitControl;
  onChangeCardType: (cardType: TCardType) => void;
}

export interface CreateCardChoiceCardsProps {
  selectedCardType: TCardType;
  onChange: (cardType: TCardType) => void;
}

export const CREATE_CARD_TITLES = {
  DETAILS: "Details",
  SPEND_CONTROLS: "Spend controls",
  ISSUE_NEW_CARD: "Issue a new card",
} as const;

export type CreateCardTitle =
  (typeof CREATE_CARD_TITLES)[keyof typeof CREATE_CARD_TITLES];
