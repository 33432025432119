import qs from "qs";
import { useEffect, useState } from "react";

import { Button } from "design_system/Button";
import { Dropdown } from "design_system/Dropdown/DropdownV2";
import { MoreHoriz } from "design_system/Icons";
import { MasterHeader } from "design_system/MasterHeader";
import { Modal } from "design_system/Modal";
import { Tag } from "design_system/Tag";

import routes from "constants/routes";
import { isAppPlatform } from "helpers/isAppPlatform";
import type ISubscriptionDetails from "interfaces/subscriptions/ISubscriptionDetailsProps";
import {
  handleDisableSubscriptionLink,
  notifyActionComplete,
  showSubscriptionDetailOptions,
} from "./helpers";

export const DetailPageHeader = ({ ...props }: ISubscriptionDetails) => {
  const {
    active,
    createdAt,
    merchant,
    paymentLinkIdentifier,
    subscriptionUrl,
    title,
  } = props;
  const [showModal, setShowModal] = useState<boolean>(false);

  const notifyCreateSubscription = (message: any) => {
    const url = new URL(window.location.href);
    url.search = "";

    notifyActionComplete(message);
    window.history.replaceState({}, "", url.href);
  };

  const handleGoBackToSubscriptions = () => {
    window.location.href = routes.MANAGE.SUBSCRIPTIONS.INDEX();
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const { message } = qs.parse(url.search, { ignoreQueryPrefix: true });

    if (message) notifyCreateSubscription(message);
  });

  if (isAppPlatform()) {
    return <></>;
  }

  return (
    <>
      <MasterHeader onBack={handleGoBackToSubscriptions}>
        <MasterHeader.Label>Subscription</MasterHeader.Label>
        <MasterHeader.Title>{title}</MasterHeader.Title>
        <MasterHeader.Tag>
          <Tag
            size="md"
            color={active ? "green" : "gray"}
            label={active ? "Active" : "Inactive"}
          />
        </MasterHeader.Tag>
        <MasterHeader.Description>{`Created on ${createdAt}`}</MasterHeader.Description>

        {active && (
          <MasterHeader.Actions>
            <Dropdown
              type="button"
              buttonProps={{
                variant: "outlined",
                size: "md",
                iconOnly: true,
                Icon: MoreHoriz,
              }}
              showTooltipOnButton={false}
              options={showSubscriptionDetailOptions({
                subscriptionUrl,
                showModal,
                setShowModal,
              })}
            />
          </MasterHeader.Actions>
        )}
      </MasterHeader>

      <Modal
        show={showModal}
        title="Are you sure you want to permanently disable this recurring payment?"
        modalWidth="45rem"
        onClose={() => setShowModal(false)}
      >
        <div className="space-y-24">
          <p className="body-medium text-center">
            Your subscribers will no longer be charged any upcoming payments and
            will be notified instantly.
          </p>

          <div className="flex flex-col gap-12">
            <Button
              variant="outlined"
              size="md"
              label="No, I'll keep it"
              onClick={() => setShowModal(false)}
              classes="w-full"
            />
            <Button
              variant="critical"
              size="md"
              label="Yes, disable this recurring payment link"
              onClick={() =>
                handleDisableSubscriptionLink({
                  paymentLinkIdentifier,
                  showModal,
                  setShowModal,
                })
              }
              classes="w-full"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
