import type React from "react";
import type { ReactNode } from "react";
import { cn } from "utils/classNames";

import { Avatar, type AvatarProps } from "design_system/Avatar/Avatar";
import { Tag } from "design_system/Tag";
import type { TTagProps } from "design_system/Tag/Tag";

// Types for the props
type TableRecordProps = {
  children: React.ReactNode;
  variant?: "noAvatar";
  className?: string;
  onClick?: () => void;
};

type TagProps = {
  tagProps: TTagProps;
};

type ColumnProps = {
  className?: string;
  label: React.ReactNode;
  supportingText?: string | string[] | ReactNode;
  onClick?: () => void;
};

type ActionProps = {
  className?: string;
  children: React.ReactNode;
};
/**
 * Main table record component
 * @param props - Props containing children and optional onClick handler
 * @returns JSX Element
 */
export const TableRecord: React.FC<TableRecordProps> & {
  Avatar: React.FC<AvatarProps>;
  Tag: React.FC<TagProps>;
  Column1: React.FC<ColumnProps>;
  Column2: React.FC<ColumnProps>;
  Actions: React.FC<ActionProps>;
} = ({ children, className, variant, onClick }: TableRecordProps) => {
  return (
    <div
      className={cn("grid w-full cursor-pointer gap-x-12 p-16", {
        "grid-cols-table-record": !variant,
        "grid-cols-table-record-no-avatar": variant === "noAvatar",
        [className]: !!className,
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

/**
 * Tag component for the table record
 * @param props - Props containing tagProps
 * @returns JSX Element
 */
TableRecord.Tag = ({ tagProps }: TagProps) => (
  <div>
    <Tag {...tagProps} />
  </div>
);

/**
 * Avatar component for the table record
 * @param props - Props containing AvatarProps
 * @returns JSX Element
 */
TableRecord.Avatar = ({ size, tagProps, ...restProps }: AvatarProps) => (
  <div>
    <Avatar size={size} tagProps={tagProps} {...restProps} />
  </div>
);

/**
 * Column component for the table record
 * @param props - Props containing label, supportingText, optional onClick, isFixedWidth, and isLargeLabel
 * @returns JSX Element
 */
TableRecord.Column1 = ({
  className,
  label,
  supportingText,
  onClick,
}: ColumnProps) => {
  return (
    <div className="flex flex-col" onClick={onClick}>
      <div className={cn("label-large flex-grow", className)}>{label}</div>
      <div className="body-small flex-grow space-y-4 text-text-color-02">
        {typeof supportingText === "object"
          ? Array.isArray(supportingText)
            ? supportingText.map((text, index) => (
                <div key={`${label}-${index}`}>{text}</div>
              ))
            : supportingText
          : supportingText}
      </div>
    </div>
  );
};

TableRecord.Column2 = ({
  className,
  label,
  supportingText,
  onClick,
}: ColumnProps) => {
  return (
    <div onClick={onClick}>
      <div className={cn("body-large flex-grow text-right", className)}>
        {label}
      </div>
      <div className="body-small flex-grow text-right text-text-color-02">
        {typeof supportingText === "object"
          ? Array.isArray(supportingText)
            ? supportingText.map((text, index) => (
                <div key={`${label}-${index}`}>{text}</div>
              ))
            : supportingText
          : supportingText}
      </div>
    </div>
  );
};

TableRecord.Actions = ({ children }: ActionProps) => {
  return <div className="block text-right">{children}</div>;
};
