import { createSlice } from "@reduxjs/toolkit";
import { CREATE_CARD_TITLES } from "../types/CreateCard.types";
import type {
  CreateCardSlice,
  SetIsKycRequiredAction,
  SetPageTitleAction,
} from "../types/CreateCardSlice.types";

const initialState: CreateCardSlice = {
  isKycRequired: false,
  page: {
    title: CREATE_CARD_TITLES.ISSUE_NEW_CARD,
  },
};

export const createCardSlice = createSlice({
  name: "createCard",
  initialState,
  reducers: {
    setIsKycRequired: (state, action: SetIsKycRequiredAction) => {
      state.isKycRequired = action.payload;
    },
    setPageTitle: (state, action: SetPageTitleAction) => {
      state.page.title = action.payload;
    },
  },
});

export const { setIsKycRequired, setPageTitle } = createCardSlice.actions;

export default createCardSlice.reducer;
