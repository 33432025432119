import type { PaginatedRequest } from "repositories/_base/pagination.types";

export const FINANCES_KEYS = {
  FINANCES: ["finances"],
  TRANSACTIONS: (identifier: string, paginationMeta?: PaginatedRequest) => [
    "finances",
    identifier,
    paginationMeta,
  ],
  BY_IDENTIFIER: (identifier: string) => ["finances", identifier],
  TRANSFER_ACCOUNTS: ["transfer-accounts"],
  TRANSFER_FUNDS: ["transfer-funds"],
} as const;
